import React, { useRef, useEffect, useState, Fragment } from 'react';
import { Container, ContainerFlex, Button, TextLocalized, Card, TextHtml, Tooltip } from '../../styled/Styled';

import { mdiAccount, mdiTimerOutline, mdiArrowLeft, mdiTwitter, mdiFacebook, mdiEmail, mdiArrowRight, mdiDownloadBox } from '@mdi/js';
import Icon from '@mdi/react';
import { useHistory, useParams } from 'react-router-dom';
import { HttpConnection, HttpOperations, imageURL } from '../../util/HttpConnection';
import { differenceInDays, format } from 'date-fns';

const AcordeonItem = ({ title, text }) => {
    const [expand, setExpand] = useState(false);

    const getHeight = () => expand ? "" : "60px";

    return <ContainerFlex width="12" px="32">
        <Container width="6" fontSize="18px" fontWeight="500" children={title} />
        <Container width="6" fontSize="18px">
            <pre style={{ fontFamily: "Poppins", margin: "0px", maxWidth: "100%", height: getHeight(), whiteSpace: "pre-wrap", overflowY: "hidden" }} children={text} />
            <Button pv="16" shadow={false} fontWeight="bold" fontSize="12px" onclick={() => setExpand(!expand)} children={expand ? "Read less" : "Read more"} />
        </Container>
    </ContainerFlex>
}

const CircleItem = (props) => <Card flex={true} shadow={false}
    minwidth="48px" minheight="48px" radius="24px"
    justify="center" align="center"
    border="solid" borderWidth="2px" borderColor="colorwhite"
    fontSize="22px"
    {...props} />

const ItemResource = ({ file }) => {
    return <ContainerFlex width="12" justify="flex-end">
        <Tooltip text="msgDownloadDoc">
            <Button width="12" shadow={false} flex={true} justify="flex-end" align="center" onclick={() => window.open(file.url, "_blank")}>
                <Container fontWeight="bold" fontColor="colorblack" ph="16" children={file.name} />
                <Icon path={mdiDownloadBox} color="var(--colorprimary)" size="48px" />
            </Button>
        </Tooltip>
    </ContainerFlex>
}

const ChallengeFullBrief = ({ item }) => {

    return <ContainerFlex justify="center">

        <Container width="8" pv="64">
            <Container width="12" textAlign="center" fontWeight="500" fontSize="30px" pb="32" children={<TextHtml children={'FULL INFORMATION'} />} />
            <AcordeonItem title="THE OPPORTUNITY" text={item.opportunity_text} />
            <Container width="12" height="2" color="colorblack" />
            <AcordeonItem title="WHO CAN PARTICIPATE?" text={item.who_text} />
            {
                item.how_text ?
                    <Fragment>
                        <Container width="12" height="2" color="colorblack" />
                        <AcordeonItem title="HOW TO PARTICIPATE?" text={item.how_text} />
                    </Fragment>
                    : null
            }

        </Container>
        {
            item.criterias_text || item.jury_text ?
                <Card shadow={false} width="8" pv="64" color="colorgray" border="solid" borderWidth="2px" borderColor="colorblack" radius="4px" mb="64">
                    <Container width="12" textAlign="center" fontWeight="500" fontSize="30px" pb="32" children={<TextHtml children={'EVALUATION'} />} />
                    {item.criterias_text ? <AcordeonItem title="CRITERIA" text={item.criterias_text} /> : null}
                    {item.criterias_text && item.jury_text ? <Container width="12" height="2" color="colorblack" /> : null}
                    {item.jury_text ? <AcordeonItem title="JURY MEMBERS" text={item.jury_text} /> : null}
                </Card>
                : null
        }
    </ContainerFlex>
}

const ChallengeOrganizersView = ({ item }) => {
    return <ContainerFlex justify="center" align="center" pv="64">
        <Container width="12" textAlign="center" fontWeight="500" fontSize="30px" pb="32" children={<TextHtml children={'ORGANISER & FACILITATOR'} />} />
        <Container width="8">
            <AcordeonItem title="ABOUT THE ORGANISER" text={item.organizer_text} />
            {
                item.facilitator_text ?
                    <Fragment>
                        <Container width="12" height="2" color="colorblack" />
                        <AcordeonItem title="FACILITATOR" text={item.facilitator_text} />
                    </Fragment>
                    : null
            }
        </Container>
    </ContainerFlex>
}

const ChallengeScheduleView = ({ item }) => {

    const [currentPhase, setCurrentPhase] = useState(0);
    const [circles, setCircles] = useState([]);

    const _prevPhase = () => setCurrentPhase(currentPhase === 0 ? item.phases.length - 1 : currentPhase - 1);
    const _nextPhase = () => setCurrentPhase(currentPhase === item.phases.length - 1 ? 0 : currentPhase + 1);

    useEffect(() => {
        let circles = [];
        for (let i = 0; i < item.phases.length; i++) {
            circles.push(
                <Card key={i} flex={true} shadow={false}
                    mh="8"
                    minwidth="16px" minheight="16px" radius="50%"
                    border="solid" borderWidth="2px" borderColor="colorblack"
                    color={i === currentPhase ? "colorblack" : "colorwhite"} />
            )
        }
        setCircles(circles);
    }, [currentPhase, item.phases.length])

    return <ContainerFlex width="12">

        <ContainerFlex ph="32" pv="64" justify="center" width="12">
            <Container width="12" textAlign="center" fontWeight="500" fontSize="30px" pb="32" children={<TextHtml children={'PHASES'} />} />

            <ContainerFlex align="center" width="12" lg="6">
                <Icon onClick={_prevPhase} color="black" size={"24px"} path={mdiArrowLeft} style={{ cursor: 'pointer' }} />

                <Container width="wrap" ph="32">
                    <Card shadow={false} border="solid" borderColor="colorblack" borderWidth="2px" px="32" width="12" >
                        <Container fontWeight="bold" fontSize="30px" pb="8" children={<TextHtml children={item.phases[currentPhase].title} />} />
                        <ContainerFlex fontWeight="500" fontSize="12px" pb="32">
                            <TextHtml children="From" />
                            <Container mh="16" children={<TextHtml children={format(new Date(item.phases[currentPhase].start_date.replace(" ", "T")), 'dd/MM/yyyy')} />} />
                            <TextHtml children="To" />
                            <Container mh="16" children={<TextHtml children={format(new Date(item.phases[currentPhase].end_date.replace(" ", "T")), 'dd/MM/yyyy')} />} />
                        </ContainerFlex>
                        <TextHtml children={item.phases[currentPhase].description} />
                    </Card>
                    <ContainerFlex width="12" pv="32" justify="center" children={circles} />
                </Container>

                <Icon onClick={_nextPhase} color="black" size={"24px"} path={mdiArrowRight} style={{ cursor: 'pointer' }} />
            </ContainerFlex>
        </ContainerFlex>

    </ContainerFlex>
}

const ChallengeBriefView = ({ item }) => {
    return <ContainerFlex width="12" justify="center" color="colorwhite" pv="64">
        <Container width="11" lg="8">
            <Container width="12" textAlign="center" fontWeight="500" fontSize="30px" pb="32" children={<TextHtml children={'THE CHALLENGE'} />} />
            <Container mv="30" children={<TextHtml children={item.full_description} />} />
        </Container>

        {
            item.resources.length > 0 ?
                <Fragment>
                    <Container width="8" height="2" color="colorblack" />

                    <ContainerFlex width="8" pv="30">
                        <Container width="12" fontSize="18px" fontWeight="500" children={<TextLocalized children="msgAddittionalInfo" />} />
                        <ContainerFlex width="12">
                            {item.resources.map(file => <ItemResource key={file.id} file={file} item={item} />)}
                        </ContainerFlex>
                    </ContainerFlex>
                </Fragment>
                : null
        }
    </ContainerFlex>
}

const StepRow = ({ item, stepsNumer, currentStep }) => {
    const colorBackground = (index) => currentStep === index ? "colorwhite" : "colorprimary"
    const colorText = (index) => currentStep !== index ? "colorwhite" : "colorprimary"

    const getMiddleCircles = () => {
        let middleCircles = [];
        for (let i = 0; i < stepsNumer - 1; i++) {
            middleCircles.push(
                <Fragment key={stepsNumer + i}>
                    <Container minwidth="30px" minheight="2px" color="colorwhite" />
                    <CircleItem color={colorBackground(i + 1)} fontColor={colorText(i + 1)} children={i + 2} />
                </Fragment>
            )
        }
        return middleCircles;
    }

    return <Fragment>
        <ContainerFlex align="center">
            <CircleItem color={colorBackground(0)} fontColor={colorText(0)} children="1" />
            {getMiddleCircles()}
        </ContainerFlex>
        <Container mh="32" fontColor="colorwhite" fontSize="22px" children={`Phase ${currentStep + 1} - ${item.phases[currentStep].title}`} />
    </Fragment>
}

const ChallengeDetailView = () => {

    const [tabActive, setTabActive] = useState(0);
    const [item, setItem] = useState();
    const [currentPhase, setCurrentPhase] = useState(0);
    const [isSticky, setIsSticky] = useState(false);

    const stickyRef = useRef();
    const history = useHistory();
    const params = useParams();

    const handleScroll = () => setIsSticky(stickyRef.current ? stickyRef.current.getBoundingClientRect().top <= 0 : false);

    useEffect(() => {
        //Sticky header
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [])

    useEffect(() => {
        const requestAPI = async () => {
            HttpConnection(`${HttpOperations.challenges}/${params.id}`,
                item => {
                    console.log(item)
                    setItem(item)
                    setCurrentPhase(currentStep(item))
                },
                error => console.log(error));
        }
        requestAPI();
    }, [params.id])

    const currentStep = (item) => {
        let current = 0;
        item.phases.forEach((phase, index) => {
            if (new Date(phase.end_date) >= new Date())
                current = index;
        });
        console.log(current)
        return current;
    }

    const socialLinks = (mode) => {
        let link = "";
        switch (mode) {
            case "face":
                link = "https://www.facebook.com/sharer/sharer.php?u=";
                break;
            case "twitter":
                link = "https://twitter.com/intent/tweet?url=";
                break;
            default:
                link = "mailto:info@example.com?&subject=&body=";
                break;
        }
        link += `https://ebrebioesfera.ambitcluster.org/challenge/${item.id}`;
        window.open(link, "_blank");
    }

    const getDays = () => {
        let days = differenceInDays(new Date(item.phases[item.phases.length - 1].end_date.replace(" ", "T")), new Date());
        return days < 0 ? "EXPIRED" : `${days} days left`;
    }

    return item ? <Container width="12">

        <ContainerFlex minheight="calc(100vh - 106px)" direction="column">

            <ContainerFlex width="12" align="flex-end" image={`${imageURL}${item.image}`} flexMode="height">
                <ContainerFlex width="12" pv="16" justify="center" align="center" color="colorprimary" children={<StepRow item={item} stepsNumer={item.phases.length} currentStep={currentPhase} />} />
            </ContainerFlex>

            <Container myRef={stickyRef} opacity={isSticky ? "0" : "1"} top="0" elevation="2" width="12">

                <ContainerFlex width="12" justify="space-between" align="center" ph="32" pv="16" color="colorwhite">
                    <ContainerFlex width="12" sm="wrap" justify="center" align="center">
                        <img alt="logo-bussiness" src={`${imageURL}${item.entity.image}`} width="120px" height="120px" />
                        <Container width="12" sm="wrap">
                            <Container width="12" sm="8" ph="32" fontWeight="500" fontSize={`${isSticky ? 27 : 37}px`} align="center" children={item.title} />
                        </Container>
                    </ContainerFlex>

                    <ContainerFlex align="center">
                        <ContainerFlex align="center" mh="16">
                            <Icon path={mdiAccount} color="black" size={1} />
                            <Container ml="4" children={item.team.map(team => team.name).join(", ")} />
                        </ContainerFlex>

                        <ContainerFlex align="center" mh="16">
                            <Icon path={mdiTimerOutline} color="black" size={1} />
                            <Container ml="4" children={getDays()} />
                        </ContainerFlex>

                        {
                            differenceInDays(new Date(item.phases[item.phases.length - 1].end_date.replace(" ", "T")), new Date()) > 0
                                ? <Button onclick={() => history.push(`/participate/${item.id}`)} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                                    <TextLocalized children="actionParticipate" />
                                </Button> : null
                        }
                    </ContainerFlex>
                </ContainerFlex>

                <Container width="12">
                    <Container width="12" minheight="1px" color="colorgraydark" />
                    <ContainerFlex width="12" color="colorgray" pv="8" ph="32" justify="center" fontColor="colorprimary" align="center">
                        <Button onclick={() => setTabActive(0)} shadow={false} px="16" fontWeight={tabActive === 0 ? "bold" : "normal"} children="Brief" />
                        <Button onclick={() => setTabActive(1)} shadow={false} px="16" fontWeight={tabActive === 1 ? "bold" : "normal"} children="Phases" />
                        <Button onclick={() => setTabActive(2)} shadow={false} px="16" fontWeight={tabActive === 2 ? "bold" : "normal"} children="Organizers" />
                        <Button onclick={() => setTabActive(3)} shadow={false} px="16" fontWeight={tabActive === 3 ? "bold" : "normal"} children="Full brief" />
                    </ContainerFlex>
                    <Container width="12" minheight="1px" color="colorgraydark" />
                </Container>

            </Container>

        </ContainerFlex>

        <Container width="12" position={isSticky ? "fixed" : null} display={isSticky ? "block" : "none"} top="0" elevation="2">

            <ContainerFlex width="12" justify="space-between" align="center" ph="32" pv="0" color="colorwhite">
                <ContainerFlex width="12" sm="wrap" justify="center" align="center">
                    <Button onclick={() => history.goBack()} radius="50%" minwidth="48px" height="48px" flex={true} justify="center" align="center" color="colorprimary"
                        children={<Icon path={mdiArrowLeft} color="white" size={1} />} />
                    <Container width="12" sm="wrap">
                        <Container width="12" sm="8" ph="32" fontWeight="500" fontSize={`${isSticky ? 22 : 37}px`} align="center" children={item.title} />
                    </Container>
                </ContainerFlex>

                <ContainerFlex align="center">

                    <ContainerFlex justify="center" mv="16" fontColor="colorblack">
                        <Icon onClick={() => socialLinks("twitter")} path={mdiTwitter} size={1} style={{ cursor: 'pointer', margin: '0 16px' }} />
                        <Icon onClick={() => socialLinks("face")} path={mdiFacebook} size={1} style={{ cursor: 'pointer', margin: '0 16px' }} />
                        <Icon onClick={() => socialLinks("mail")} path={mdiEmail} size={1} style={{ cursor: 'pointer', margin: '0 16px' }} />
                    </ContainerFlex>

                    {
                        differenceInDays(new Date(item.phases[item.phases.length - 1].end_date.replace(" ", "T")), new Date()) > 0
                            ? <Button onclick={() => history.push("participate")} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                                <TextLocalized children="actionParticipate" />
                            </Button>
                            : null
                    }
                </ContainerFlex>
            </ContainerFlex>

            <Container width="12" minheight="1px" color="colorgraydark" />

            <ContainerFlex width="12" color="colorgray" pv="8" ph="32" justify="center" fontColor="colorprimary" align="center">
                <Button onclick={() => setTabActive(0)} shadow={false} px="16" fontWeight={tabActive === 0 ? "bold" : "normal"} children="Brief" />
                <Button onclick={() => setTabActive(1)} shadow={false} px="16" fontWeight={tabActive === 1 ? "bold" : "normal"} children="Phases" />
                <Button onclick={() => setTabActive(2)} shadow={false} px="16" fontWeight={tabActive === 2 ? "bold" : "normal"} children="Organizers" />
                <Button onclick={() => setTabActive(3)} shadow={false} px="16" fontWeight={tabActive === 3 ? "bold" : "normal"} children="Full brief" />
            </ContainerFlex>

            <Container width="12" minheight="1px" color="colorgraydark" />

        </Container>

        <Container>
            {
                tabActive === 0 ? <ChallengeBriefView item={item} />
                    : tabActive === 1 ? <ChallengeScheduleView item={item} />
                        : tabActive === 2 ? <ChallengeOrganizersView item={item} />
                            : <ChallengeFullBrief item={item} />
            }
        </Container>

        <ContainerFlex width="12" color="colorprimarydark" justify="center">
            <Container mx="32" textAlign="center" width="12" fontColor="colorwhite">
                <TextLocalized children="msgShareChallenge" />
                <ContainerFlex justify="center" mv="16">
                    <Button onclick={() => socialLinks("twitter")} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                        <Icon path={mdiTwitter} color="white" size={2} />
                    </Button>
                    <Button onclick={() => socialLinks("face")} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                        <Icon path={mdiFacebook} color="white" size={2} />
                    </Button>
                    <Button onclick={() => socialLinks("mail")} border="solid" borderWidth="2px" borderColor="colorwhite" px="8" radius="50%" maxheight="68px" mh="16">
                        <Icon path={mdiEmail} color="white" size={2} />
                    </Button>
                </ContainerFlex>
            </Container>
        </ContainerFlex>

    </Container>
        : null
};

export default ChallengeDetailView;