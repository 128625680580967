import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Card, Container, ContainerFlex, TextLocalized } from '../styled/Styled';
import { HttpConnection, HttpMethod, HttpOperations } from '../util/HttpConnection';

import name from '../../images/img/logo_black.png'
import home from '../../images/img/background-home.jpg';

import { countries, profileOptions } from '../../constantes/model';
import { LanguageContext } from '../providers/LanguageProvider';
import { UserAction, UserContext } from '../providers/UserProvider';
import { InputWrapper } from './forms/ContactFormView';


const Register = () => {
    const [formData, setFormData] = useState({ name: "", lastname: "", country: "", profile: "", email: "", password: "" })
    const { dispatch } = useContext(UserContext);
    const { dictionary } = useContext(LanguageContext);
    let history = useHistory();

    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value });

    const onChangeSelect = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const sendRequest = () => {
        if (formData.email !== "" && formData.password !== "" && formData.name !== "" && formData.lastname !== "" && formData.country !== "" && formData.profile !== "") {
            HttpConnection(HttpOperations.user, response => {
                dispatch({ action: UserAction.login, data: response });
                history.replace("/");
            }, error => dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorSave" } }), HttpMethod.post, formData);
        } else {
            dispatch({ action: UserAction.showNoti, data: { show: true, color: "colorred", message: "errorOblData" } });
        }
    }

    return (
        <ContainerFlex width="12" justify="center" minheight="calc(100vh - 64px)" image={home}>
            <ContainerFlex width="12" minheight="calc(100vh - 64px)" pv="32" justify="center" align="center" color="colorgray-a50">
                <ContainerFlex width="12" lg="8" height="100%" justify="center" align="center" >
                    <Card flex align="center" width="12" lg="8" shadowOn color="colorwhite" px="16" radius="20px">
                        <ContainerFlex width="12" lg="6" height="100%" justify="center">
                            <ContainerFlex width="12" justify="center" align="center" children={<img alt="logo" src={name} height="70px" />} />
                            <ContainerFlex width="12" pv="16" ph="24" justify="center" align="center" textAlign="center" children={<TextLocalized children="msgRegisterText" />} />
                        </ContainerFlex>
                        <Container width="12" lg="6">
                            <ContainerFlex width="12" fontSize="25px" fontWeight="300" align="center" children={<TextLocalized children="msgUserInfo" />} />
                            <InputWrapper children={<input name="name" type="text" value={formData.name} onChange={onChange} placeholder={`${dictionary["msgFirstname"]}`} />} />
                            <InputWrapper children={<input name="lastname" type="text" value={formData.lastname} onChange={onChange} placeholder={`${dictionary["msgLastname"]}`} />} />

                            <InputWrapper>
                                <select name='country' onChange={onChangeSelect} style={{ width: "100%" }}>
                                    <option value="" children={dictionary["msgCountry"]} />
                                    {countries.map((country, index) => <option key={index} value={dictionary[country]} children={dictionary[country]} />)}
                                </select>
                            </InputWrapper>

                            <InputWrapper>
                                <select name='profile' onChange={onChangeSelect} style={{ width: "100%" }}>
                                    <option value="" children={dictionary["msgProfile"]} />
                                    {profileOptions.map((profile, index) => <option key={index} value={dictionary[profile]} children={dictionary[profile]} />)}
                                </select>
                            </InputWrapper>

                            <ContainerFlex width="12" fontSize="25px" fontWeight="300" mt="32" align="center" children={<TextLocalized children="msgAccountInfo" />} />

                            <InputWrapper children={<input name="email" type="email" value={formData.email} onChange={onChange} placeholder={`${dictionary["msgEmail"]}`} />} />
                            <InputWrapper children={<input name="password" type="password" value={formData.password} onChange={onChange} placeholder={`${dictionary["msgPassword"]}`} />} />

                            <Button onclick={sendRequest} textAlign="center" px="12" mt="32" mb="16" fontWeight="300" color="colorprimary" hoverColor="color-blue-dark" fontColor="colorwhite" radius="30px" children={<TextLocalized children="actionRegister" />} />
                        </Container>
                    </Card>
                </ContainerFlex>
            </ContainerFlex>
        </ContainerFlex>
    );
};

export default Register;