import React, { useEffect, useState } from 'react';
import { Container, TextLocalized, ContainerFlex, Button } from '../styled/Styled';
import img_home from '../../images/img/background-home.jpg';
import user_one from '../../images/img/user_one.jpg';
import user_two from '../../images/img/user_two.jpg';
import user_three from '../../images/img/user_three.jpg';
import user_four from '../../images/img/user_four.jpg';
import user_five from '../../images/img/user_five.jpg';
import user_six from '../../images/img/user_six.jpg';

import partner1 from '../../images/img/partner1.png';
import partner2 from '../../images/img/partner2.png';
import partner3 from '../../images/img/partner3.png';
import partner4 from '../../images/img/partner4.png';
import partner5 from '../../images/img/partner5.png';
import partner6 from '../../images/img/partner6.png';
import partner7 from '../../images/img/partner7.png';
import partner8 from '../../images/img/partner8.png';
import partner10 from '../../images/img/partner10.png';

import ChallengeView from './challenge/ChallengeView';
import { useHistory } from 'react-router-dom';

import { HttpConnection, HttpMethod, HttpOperations } from '../util/HttpConnection';
import { filterDefault } from '../util/ConstantUtil';
import TrainingResourceView from './training/TrainingResourceView';

const HomeView = () => {
    const [challenges, setChallenges] = useState([]);
    const [training, setTraining] = useState([]);
    let history = useHistory();

    useEffect(() => {
        HttpConnection(HttpOperations.challenges,
            item => setChallenges(item),
            error => console.log(error), HttpMethod.post, { ...filterDefault, limit: 6 });

        HttpConnection(HttpOperations.trainings,
            item => setTraining(item),
            error => console.log(error), HttpMethod.get);
    }, [])

    return (
        <Container>
            <Container width="12" image={img_home}>

                <ContainerFlex width="12" color="colorgrayalpha" pv="150" justify="center" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                    <ContainerFlex width="12" justify="center" textAlign="center" children={<TextLocalized width="12" children="msgHomeTitleOne" />} />
                    <ContainerFlex width="8" fontWeight="400" fontSize="18px" justify="center" ph="32" mv="16" children={<TextLocalized children="msgHomeSubTitle" />} />
                    <ContainerFlex width="12" justify="center" align="center" mv="32">
                        <Button onclick={() => history.push("challenges")} mx="16" ph="64" pv="16"
                            color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal"
                            children={<TextLocalized children="msgBtnOne" />} />

                        <Button onclick={() => history.push("contact")} mx="16" ph="64" pv="16"
                            radius="30px" fontSize="17px" fontWeight="normal" border="solid" borderWidth="2px" borderColor="colorwhite"
                            children={<TextLocalized children="msgBtnTwo" />} />

                        <Button onclick={() => history.push("training")} mx="16" ph="64" pv="16"
                            color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal"
                            children={<TextLocalized children="msgBtnThree" />} />
                    </ContainerFlex>
                </ContainerFlex>
            </Container>

            <ContainerFlex width="12" justify="center" pv="48">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="colorprimary" textAlign="center">
                    <TextLocalized children="msgHomeScOneTitle" />
                </Container>

                <ChallengeView items={challenges} />

                <ContainerFlex width="12" justify="center" align="center" mt="32">
                    <Button onclick={() => history.push("/challenges")} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                        <TextLocalized children="msgHomeScOneButton" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex width="12" justify="center" pv="48">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="colorprimary" textAlign="center" ph="16">
                    <TextLocalized children="msgHomeScTwoTitle" />
                </Container>
                <Container width="12" textAlign="center" mt="16">
                    <TextLocalized children="msgHomeScTwoSubtitle" />
                </Container>
                <ContainerFlex width="12" mt="64">
                    <ContainerFlex width="12" lg="4" height="300px" image={user_two}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser2" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex width="12" lg="4" height="300px" image={user_four}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser4" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex width="12" lg="4" height="300px" image={user_three}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser1" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex width="12" lg="4" height="300px" image={user_one}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser3" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex width="12" lg="4" height="300px" image={user_five}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser5" />
                        </ContainerFlex>
                    </ContainerFlex>
                    <ContainerFlex width="12" lg="4" height="300px" image={user_six}>
                        <ContainerFlex width="12" color="colorgrayalpha" minheight="100%" justify="center" align="center" ph="32" fontWeight="bold" fontSize="37px" fontColor="colorwhite" textAlign="center">
                            <TextLocalized children="msgUser6" />
                        </ContainerFlex>
                    </ContainerFlex>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex width="12" justify="center" pv="48">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="colorprimary" textAlign="center" children={<TextLocalized children="msgHomeScFourTitle" />} />
                <Container width="12" mt="16" textAlign="center" children={<TextLocalized children="msgHomeScFourText" />} />

                <TrainingResourceView items={training.filter(item => item.training_category_id === 1).slice(0, 2)} />

                <ContainerFlex width="12" justify="center" align="center" mt="32">
                    <Button onclick={() => history.push("/training")} shadow={false} mx="16" ph="64" pv="16" color="colorprimarylight" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                        <TextLocalized children="msgHomeScFourButton" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>

            <ContainerFlex display="none" width="12" pv="48" justify="center">
                <Container width="12" fontSize="28px" fontWeight="bold" fontColor="colorprimary" textAlign="center">
                    <TextLocalized children="msgHomeScThreeTitle" />
                </Container>
                <Container width="12" textAlign="center" mt="16">
                    <TextLocalized children="msgHomeScThreeSubtitle" />
                </Container>

                <ContainerFlex width="12" lg="10" justify="center" align="center" mv="32">
                    <img alt="partner-10" width="220" style={{ padding: '32px' }} src={partner10} />
                    <img alt="partner-8" width="220" style={{ padding: '32px' }} src={partner8} />
                    <img alt="partner-7" width="220" style={{ padding: '32px' }} src={partner7} />
                    <img alt="partner-6" width="220" style={{ padding: '32px' }} src={partner6} />
                    <img alt="partner-5" width="220" style={{ padding: '32px' }} src={partner5} />
                    <img alt="partner-4" width="220" style={{ padding: '32px' }} src={partner4} />
                    <img alt="partner-3" width="220" style={{ padding: '32px' }} src={partner3} />
                    <img alt="partner-2" width="220" style={{ padding: '32px' }} src={partner2} />
                    <img alt="partner-1" width="220" style={{ padding: '32px' }} src={partner1} />
                </ContainerFlex>

                <ContainerFlex width="12" justify="center" align="center" mb="48">
                    <Button onclick={() => history.push("contact")} shadow={false} mx="16" ph="64" pv="16" color="colorprimary" radius="30px" fontSize="17px" fontWeight="normal" fontColor="colorwhite" textAlign="center">
                        <TextLocalized children="msgHomeScThreeButton" />
                    </Button>
                </ContainerFlex>
            </ContainerFlex>

        </Container>
    );
};

export default HomeView;